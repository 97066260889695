import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Title from "../components/Title"
import MenuLink from "../components/MenuLink"
import Breadcrumb from "../components/Breadcrumb"

const Portal = ({ pageContext, data }) => {
  return (
    <Layout>
      <Metas title={pageContext.title} />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <Breadcrumb />

          <Title
            title={pageContext.title}
            className="text-primary"
            typesense={false}
          />

          <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {data.childrens.nodes.map((item, index) => (
              <div key={index} className="column portal-link h-full">
                <MenuLink
                  internalId={item.link.uri}
                  className="group block relative px-4 pt-4 pb-12 bg-secondary text-white md:h-32 hover:text-white"
                >
                  <span className="text-lg font-semibold">{item.title}</span>
                  <div className="icon absolute bottom-0 right-0 px-4 py-2 text-lg bg-primary text-white transform translate-x-1/4 translate-y-1/4">
                    <i className="fas fa-chevron-right">
                      <span className="hidden">En lire plus</span>
                    </i>
                  </div>
                </MenuLink>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Portal

export const query = graphql`
  query portalPageElements($parentId: String!) {
    childrens: allMenuLinkContentMenuLinkContent(
      filter: {
        enabled: { eq: true }
        menu_name: { eq: "main" }
        drupal_parent_menu_item: { eq: $parentId }
      }
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        title
        drupal_parent_menu_item
        drupal_id
        link {
          uri
          options {
            attributes {
              class
              target
              data_has_icon
              data_icon
              data_text_invisible
            }
          }
        }
      }
    }
  }
`
